<template>
	<div class="schema-settings">
		<div class="actions">
			<button class="button w-button" @click="saveSchema">Speichern</button>
			<button class="button w-button" @click="add_row">
				Neuen Wert hinzufügen
			</button>
		</div>
		<div class="schema-infos">
			<p><small>Doppelklick auf einen Wert, um ihn zu bearbeiten</small></p>
			<p class="has-error" v-show="changed">
				Sie haben ungespeicherte Änderungen!
			</p>
		</div>
		<table class="table">
			<thead>
				<th>Abkürzung</th>
				<th>Definition</th>
				<th>Aktionen</th>
			</thead>
			<tbody>
				<tr v-for="item in $v.schema.$each.$iter" :key="item.id">
					<td :class="{ 'has-error': item.key.$error }">
						<input
							type="text"
							v-model="item.$model.key"
							:readonly="!editable.includes(item.$model.id)"
							@dblclick="enable(item.$model.id)"
						/>
						<small
							class="has-error"
							v-if="item.key.$error && !item.key.required"
							>Die Abkürzung ist notwendig!</small
						>
						<small class="has-error" v-if="item.key.$error && !item.key.unique"
							>Abkürzungen müssen eindeutig sein!</small
						>
					</td>
					<td :class="{ 'has-error': item.value.$error }">
						<input
							type="text"
							v-model="item.$model.value"
							:readonly="!editable.includes(item.$model.id)"
							@dblclick="enable(item.$model.id)"
						/>
						<small class="has-error" v-if="item.$error && !item.value.required"
							>Die Definition ist notwendig!</small
						>
					</td>
					<td>
						<a class="option" @click.prevent="remove_entry(item.$model.key)">
							<unicon
								name="trash-alt"
								:fill="'#006a9f'"
								icon-style="line"
								width="21"
								height="21"
							/>
						</a>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
function unique(value) {
	return this.schema.filter((entry) => (entry.key === value.length) === 1);
}
export default {
	data() {
		return {
			schema: [],
			editable: [],
			changed: false
		};
	},
	watch: {
		schema: {
			deep: true,
			handler(val, prevVal) {
				if (prevVal.length !== 0) {
					this.changed = true;
				}
			}
		}
	},
	validations: {
		schema: {
			$each: {
				key: {
					required,
					unique
				},
				value: {
					required
				}
			}
		}
	},
	mounted() {
		this.load_schema();
	},
	methods: {
		async saveSchema() {
			this.$v.$touch();
			if (!this.$v.$anyError) {
				await this.$api.post(
					'/lab/update_schema',
					{
						schema: JSON.stringify(this.schema)
					},
					{
						headers: {
							Authorization: `Bearer ${this.$store.getters.getUserToken}`
						}
					}
				);
				this.$store.dispatch('updateSchema', {
					schema: JSON.stringify(this.schema)
				});
				this.clear_fields();
				this.load_schema();
			}
		},
		load_schema() {
			let schema = JSON.parse(this.$store.getters.getLab.schema);
			for (let i = 0; i < schema.length; i++) {
				schema[i].id = i;
			}

			this.schema = schema || [];
			this.changed = false;
		},
		add_row() {
			this.schema.unshift({
				id: this.schema.length,
				key: '',
				value: '',
				readonly: false
			});
		},
		enable(item) {
			if (!this.editable.includes(item)) {
				this.editable.push(item);
			}
		},
		remove_entry(key) {
			this.schema = this.schema.filter((entry) => entry.key !== key);
		},
		clear_fields() {
			this.schema = [];
			this.editable = [];
			this.changed = false;
			this.$v.$reset();
		}
	}
};
</script>

<style lang="scss">
.schema-settings {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr 5rem auto;
	box-shadow: 0 0 20px -12px #394150;
	padding: 3rem;
	background-color: $background-color;
	justify-content: center;
	align-items: center;
	justify-items: center;
	.actions {
		width: 100%;
		display: flex;
		justify-content: space-evenly;
	}
	.schema-infos {
		padding: 1rem 0;
	}
	.table {
		td {
			input {
				text-align: center;
				&:read-only {
					background: none;
					border: none;
					box-shadow: none;
				}
				border-radius: 0;
			}
		}
	}
}
</style>
